import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { ReferencesBlock, PageHeader, PageSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, EndpointDisplay, ComparisonTable, PrimaryClaim, SecondaryCallout, KMCurve, PageSubSection, NCCNcallout, TextBlock } from '../../components';
import CERVICAL_1L from '../../components/StudyDesign/__study-design/cervical-1l'
import { StaticImage } from 'gatsby-plugin-image';
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-826 - Clinical Trial Results | HCP`,
    keywords: `keynote 826, advanced cervical cancer clinical trial results`,
    description: `Health care professionals may find clinical trial results for first-line combination therapy for advanced cervical cancer in KEYNOTE-826.`,
    schemaJsonLD: [`{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/cervical-cancer-first-line-combination-therapy/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/cervical-cancer-first-line-combination-therapy/","@type":"MedicalTrial","name":"KEYNOTE⁠-⁠826","description":"Clinical Findings from KEYNOTE⁠-⁠826 ","trialDesign":"A phase 3 multicenter, randomized, double blind, placebo-controlled study of KEYTRUDA + chemotherapy with or without bevacizumab for patients with persistent, recurrent, or metastatic cervical cancer in the first line (N=617)"}`
    ]
}

//from /src/util/data/indications.js
const indicationId = 28;
const jobCode = jobCodes[24].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: 'FIGO 2014 Stage III-IVA Cervical Cancer', url: '/efficacy/cervical-cancer-combination-chemoradiotherapy/' },
                { text: '2L Monotherapy Recurrent or Metastatic Cervical Cancer', url: '/efficacy/cervical-cancer-second-line-monotherapy/' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/cervical-cancer-first-line-combination-therapy/' },
                { text: 'Dosing', url: '/dosing/options/' },
                { text: 'PD&#8288;-&#8288;L1 Testing & Scoring', url: '/biomarker-testing/pd-l1/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const keynote826secondaryCalloutFootnotes = [
    {
        label: "a.",
        text: "Chemotherapy (paclitaxel and cisplatin or paclitaxel and carboplatin)."
    },
    {
        label: "b.",
        text: "Based on the stratified Cox proportional hazard model."
    },
    {
        label: "c.",
        text: "<i>P</i> value (one-sided) is compared with the allocated alpha of 0.0055 for this interim analysis (with 72% of the planned number of events for final analysis)."
    },
];

const osSurvivalRateComparisonTableFootnotes = [
    {
        label: "a.",
        text: "Chemotherapy (paclitaxel and cisplatin or paclitaxel and carboplatin)."
    },
    {
        label: "b.",
        text: "Based on the stratified Cox proportional hazard model."
    },
    {
        label: "c.",
        text: "<i>P</i> value (one-sided) is compared with the allocated alpha of 0.0055 for this interim analysis (with 72% of the planned number of events for final analysis)."
    },
];

const pfsSurvivalRateComparisonTableFootnotes = [
    {
        label: "a.",
        text: "Chemotherapy (paclitaxel and cisplatin or paclitaxel and carboplatin)."
    },
    {
        label: "b.",
        text: "Based on the stratified Cox proportional hazard model."
    },
    {
        label: "e.",
        text: "<i>P</i> value (one-sided) is compared with the allocated alpha of 0.0014 for this interim analysis (with 82% of the planned number of events for final analysis)."
    },
]

const orrKeyEndpointFootnotes = [
    {
        label: "a.",
        text: "Chemotherapy (paclitaxel and cisplatin or paclitaxel and carboplatin)."
    },
    {
        label: "f.",
        text: "Response: Best objective response as confirmed complete response or partial response."
    }
]

const orrEndpointFootnotes = [
    {
        label: "a.",
        text: "Chemotherapy (paclitaxel and cisplatin or paclitaxel and carboplatin)."
    },
    {
        label: "f.",
        text: "Response: Best objective response as confirmed complete response or partial response."
    }
]

const dorTableFootnotes = [
    {
        label: "a.",
        text: "Chemotherapy (paclitaxel and cisplatin or paclitaxel and carboplatin)."
    },
    {
        label: "+",
        text: "Denotes ongoing response."
    },
]

const kmCurveFootnotes3 = [
    {
        label: "a.",
        text: "Chemotherapy (paclitaxel and cisplatin or paclitaxel and carboplatin)."
    },
    {
        label: "b.",
        text: "Based on the stratified Cox proportional hazard model."
    },
    {
        label: "d.",
        text: "Based on the protocol-specified final OS analysis."
    }
]

const kmCurveFootnotes2 = [
    {
        label: "a.",
        text: "Chemotherapy (paclitaxel and cisplatin or paclitaxel and carboplatin)."
    },
    {
        label: 'b.',
        text: "Based on the stratified Cox proportional hazard model."
    }
]

const pageReferences = [
    {
        label: "1.",
        text: "Colombo N, Dubot C, Lorusso D, et al. Pembrolizumab for persistent, recurrent, or metastatic cervical cancer. <i> N Engl J Med.</i> 2021;385(20):1856-1867. doi:10.1056/NEJMoa2112435"
    },
    {
        label: "2.",
        text: "Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Cervical Cancer V.1.2024. © National Comprehensive Cancer Network, Inc. 2023. All rights reserved. Accessed October 4, 2023. To view the most recent and complete version of the guidelines, go online to NCCN.org."
    },
]

const category1NCCNcalloutData = {
    bodyCopy: `
        <h5>An NCCN Guidelines® Recommended Treatment Option for Eligible Patients With Recurrent or Metastatic Cervical Cancer (PD&#8288;-&#8288;L1 CPS ≥1)<sup>n-p</sup></h5>
        <p>Pembrolizumab (KEYTRUDA), in combination with chemotherapy, with or without bevacizumab, is indicated for the treatment of patients with persistent, recurrent, or metastatic cervical cancer whose tumors express PD&#8288;-&#8288;L1 (CPS ≥1) as determined by an FDA-approved test.</p>
        <p>Pembrolizumab (KEYTRUDA) + cisplatin/paclitaxel OR carboplatin/paclitaxel, with or
        without bevacizumab, for PD&#8288;-&#8288;L1 (CPS ≥1) positive recurrent or metastatic cervical cancer.</p>
        <ul>
        <ul>
            <li>Category 1, NCCN Preferred Option<sup>2,n-p</sup></li>
        </ul>
        <p>NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.</p>
        `,
    footnotes: [
        {
            label: "n.",
            text: "According to NCCN, preferred intervention is based on superior efficacy, safety, and evidence; and, when appropriate, affordability."
        },
        {
            label: "o.",
            text: "An FDA-approved biosimilar is an appropriate substitute for bevacizumab."
        },
        {
            label: "p.",
            text: "Recommended in patients whose tumors expressed PD&#8288;-&#8288;L1 (CPS ≥ 1) as determined by an FDA-approved test."
        },
    ],
    definitions: `Category 1 = Based upon high-level evidence, there is uniform National Comprehensive Cancer Network® (NCCN®) consensus that the intervention is appropriate.`,
    showBadge: false,
}

const anchorLinkModelData = [
    { label: 'Clinical Findings from KEYNOTE&#8288;-&#8288;826' },
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;826' },
    { label: 'NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®)' },
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                    <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">SUPERIOR OVERALL SURVIVAL (OS) AT IA1: In a phase 3 trial, KEYTRUDA + chemotherapy<sup>a</sup> ± bevacizumab significantly reduced the risk of death vs placebo + chemotherapy<sup>a</sup> ± bevacizumab (HR<sup>b</sup>=0.64; 95% CI, 0.50–0.81, <i>P</i><sup>c</sup>=0.0001)<sup>1</sup></PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}>
                            <PageSubSection title="Efficacy results in the first-line treatment of patients with persistent, recurrent, or metastatic cervical cancer whose tumors expressed PD&#8288;-&#8288;L1 (CPS ≥1) (n=548)">
                                <SecondaryCallout
                                    title="Superior OS with KEYTRUDA + chemotherapy<sup>a</sup> with or without bevacizumab vs placebo + chemotherapy<sup>a</sup> with or without bevacizumab at IA1.<sup>1</sup>"
                                    alt=""
                                    footnotes={keynote826secondaryCalloutFootnotes}
                                    definitions="FDA = US Food and Drug Administration; HR = hazard ratio; CI = confidence interval; IA1 = first interim analysis."
                                />

                                <ComparisonTable
                                    title="Overall Survival (OS) in KEYNOTE&#8288;-&#8288;826 (CPS ≥1) at IA1"
                                    footnotes={osSurvivalRateComparisonTableFootnotes}
                                    definitions="NR = not reached."
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA 200&nbsp;mg Q3W + Chemotherapy<sup>a</sup> ± Bevacizumab (n=273)',
                                                },
                                                {
                                                    legendTitle: 'Placebo + Chemotherapy<sup>a</sup> ± Bevacizumab (n=275)',
                                                },
                                            ],
                                            [
                                                {
                                                    colspan: 2,
                                                    centerText: true,
                                                    hrCiP: 'HR<sup>b</sup>=0.64 (95% CI, 0.50–0.81) <i>P</i><sup>c</sup>=0.0001'
                                                }
                                            ],
                                            [
                                                {
                                                    label: 'Number of patients with event (%)',
                                                    dataPoint: '118 (43.2)',
                                                },
                                                {
                                                    label: 'Number of patients with event (%)',
                                                    dataPoint: '154 (56.0)',
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median OS',
                                                    dataPoint: 'Not reached',
                                                    dataSubPoint: '(95% CI, 19.8-NR)'
                                                },
                                                {
                                                    label: 'Median OS',
                                                    dataPoint: '16.3 months',
                                                    dataSubPoint: '(95% CI, 14.5-19.4)'
                                                },
                                            ],
                                        ]
                                    } 
                                />
                                <TextBlock >
                                    <h4>Final OS Analysis</h4>
                                    <p>An updated OS analysis was conducted at the time of final analysis when 354 deaths in the CPS ≥1 population were observed.</p>
                                </TextBlock>
                                <KMCurve
                                    title="Kaplan-Meier Estimates of OS: PD&#8288;-&#8288;L1 (CPS ≥1) Population<sup>d</sup>"
                                    hr="HR<sup>b</sup>=0.60 (95% CI, 0.49-0.74)"
                                    image={() => <StaticImage
                                        src="../../assets/cervical-first-line-KMCurve-v8.png"
                                        placeholder="blurred"
                                        alt="Kaplan-Meier Estimates of OS: PD-L1 (CPS ≥1) Population in KEYNOTE-826" />}
                                    footnotes={kmCurveFootnotes3}
                                    definitions="chemo = chemotherapy."
                                />
                                <ComparisonTable
                                    title="Final OS in KEYNOTE&#8288;-&#8288;826 (CPS ≥1)"
                                    footnotes={kmCurveFootnotes2}
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA 200&nbsp;mg Q3W + Chemotherapy<sup>a</sup> ± Bevacizumab (n=273)',
                                                },
                                                {
                                                    legendTitle: 'Placebo + chemotherapy<sup>a</sup> with or without bevacizumab (n=275)',
                                                },
                                            ],
                                            [
                                                {
                                                    hrCiP: 'HR<sup>b</sup>=0.60 (95% CI, 0.49-0.74)',
                                                    centerText: true,
                                                    colspan: 2,
                                                }
                                            ],
                                            [
                                                {
                                                    label: 'Number of patients with an event (%) ',
                                                    dataPoint: '153 (56.0%) ',
                                                },
                                                {
                                                    label: 'Number of patients with an event (%) ',
                                                    dataPoint: '201 (73.1%)',
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median OS',
                                                    dataPoint: '28.6 months',
                                                    dataSubPoint: '(95% CI, 22.1–38.0)',
                                                },
                                                {
                                                    label: 'Median OS',
                                                    dataPoint: '16.5 months',
                                                    dataSubPoint: '(95% CI, 14.5–20.0)',
                                                },
                                            ],
                                        ]
                                    } />
                            </PageSubSection>
                        </PageSection>

                        <PageSection bgColor="cloud" >
                            
                                <ComparisonTable
                                    title="Progression-free Survival (PFS) in KEYNOTE&#8288;-&#8288;826 (CPS ≥1) at IA1"
                                    footnotes={pfsSurvivalRateComparisonTableFootnotes}
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA 200&nbsp;mg Q3W + Chemotherapy<sup>a</sup> ± Bevacizumab (n=273)',
                                                },
                                                {
                                                    legendTitle: 'Placebo + Chemotherapy<sup>a</sup> ± Bevacizumab (n=275)',
                                                },
                                            ],
                                            [
                                                {
                                                    colspan: 2,
                                                    centerText: true,
                                                    hrCiP: 'HR<sup>b</sup>=0.62 (95% CI, 0.50–0.77) <i>P</i><sup>e</sup><0.0001'
                                                }
                                            ],
                                            [
                                                {
                                                    label: 'Number of patients with event (%)',
                                                    dataPoint: '157 (57.5)',
                                                },
                                                {
                                                    label: 'Number of patients with event (%)',
                                                    dataPoint: '198 (72.0)',
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median PFS',
                                                    dataPoint: '10.4 months',
                                                    dataSubPoint: '(95% CI, 9.7–12.3)',
                                                },
                                                {
                                                    label: 'Median PFS',
                                                    dataPoint: '8.2 months',
                                                    dataSubPoint: '(95% CI, 6.3–8.5)',
                                                },
                                            ],
                                        ]
                                    } />

                                <EndpointDisplay
                                    title="Objective Response Rate (ORR)<sup>f</sup> in KEYNOTE&#8288;-&#8288;826 (CPS ≥1) at IA1"
                                    footnotes={orrKeyEndpointFootnotes}
                                    cards={
                                        [
                                            {
                                                dataType: 'ORR',
                                                label: 'KEYTRUDA + chemotherapy<sup>a</sup> with or without bevacizumab (n=273)',
                                                ci: '95% CI, 62–74',
                                                rate: '68%',
                                                completeResponseRate: '23%',
                                                partialResponseRate: '45%',
                                            }
                                        ]
                                    }
                                />
                                <EndpointDisplay
                                    title="Objective Response Rate (ORR)<sup>f</sup> in KEYNOTE&#8288;-&#8288;826 (CPS ≥1) at IA1"
                                    footnotes={orrEndpointFootnotes}
                                    cards={
                                        [
                                            {
                                                dataType: 'ORR',
                                                label: 'Placebo + chemotherapy<sup>a</sup> with or without bevacizumab (n=275)',
                                                ci: '95% CI, 44–56',
                                                rate: '50%',
                                                completeResponseRate: '13%',
                                                partialResponseRate: '37%',
                                            }
                                        ]
                                    }
                                />
                                <ComparisonTable
                                    title="Duration of Response (DOR) in KEYNOTE&#8288;-&#8288;826 (CPS ≥1) at IA1"
                                    footnotes={dorTableFootnotes}
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA 200&nbsp;mg Q3W + Chemotherapy<sup>a</sup> ± Bevacizumab (n=273)',
                                                },
                                                {
                                                    legendTitle: 'Placebo + Chemotherapy<sup>a</sup> ± Bevacizumab (n=275)',
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median DOR',
                                                    dataPoint: '18.0 months',
                                                    dataSubPoint: `range: (1.3+&ndash;24.2+)`
                                                },
                                                {
                                                    label: 'Median DOR',
                                                    dataPoint: '10.4 months',
                                                    dataSubPoint: `range: (1.5+&ndash;22.0+)`
                                                },
                                            ]
                                        ]
                                    } />

                            

                            <PageSubSection title={anchorLinkModelData[1].label}>
                                <CERVICAL_1L />
                            </PageSubSection>
                        </PageSection>

                        <PageSection title={anchorLinkModelData[2].label}>
                            <NCCNcallout
                                data={category1NCCNcalloutData}
                                footnotes={category1NCCNcalloutData.footnotes}
                                definitions={category1NCCNcalloutData.definitions}
                            />
                        </PageSection>
                    </TemplateColumn>
                    {/* End Main Column */}

                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
